<template>
  <b-tabs vertical content-class="col-12 col-md-9 mt-1 mt-md-0" pills nav-wrapper-class="col-md-3 col-12" nav-class="nav-left">
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">General</span>
      </template>
      <b-card>
        <!-- form -->
        <b-form class="">
          <b-row>
            <h6 class="section-label mx-1 mb-2">Shop General Settings</h6>
            <b-col md="12">
              <b-form-group label="Business Name" label-for="business-business_name" class="mb-2">
                <b-form-input id="business-business_name" v-model="generalData.business_name" />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label="Shop Slogan" label-for="business-business_slogan" class="mb-2">
                <b-form-input id="business-slogan" v-model="generalData.business_slogan" />
              </b-form-group>
            </b-col>
            <b-col cols="12"> </b-col>
            <b-col md="6">
              <b-form-group label="Shop Email Address" label-for="business-contact_email" class="mb-2">
                <b-form-input id="business-contact_email" v-model="generalData.contact_email" />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Shop Phone Number" label-for="business-contact_phone" class="mb-2">
                <b-form-input id="business-contact_phone" v-model="generalData.contact_phone" />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Facebook Link" label-for="business-contact_facebook" class="mb-2">
                <b-form-input id="business-contact_facebook" v-model="generalData.contact_facebook" />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Instagram Link" label-for="business-contact_instagram" class="mb-2">
                <b-form-input id="business-contact_instagram" v-model="generalData.contact_instagram" />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Twitter Link" label-for="business-contact_twitter" class="mb-2">
                <b-form-input id="business-contact_twitter" v-model="generalData.contact_twitter" />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Tripadvisor Link" label-for="business-contact_tripadvisor" class="mb-2">
                <b-form-input id="business-contact_tripadvisor" v-model="generalData.contact_tripadvisor" />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Yelp Link" label-for="business-contact_yelp" class="mb-2">
                <b-form-input id="business-contact_yelp" v-model="generalData.contact_yelp" />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Eircode" label-for="business-location_eircode" class="mb-2">
                <b-form-input id="business-location_eircode" v-model="generalData.location_eircode" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Shop Location Address Line 1" label-for="business-location_address1" class="mb-2">
                <b-form-input a id="business-location_addres1" v-model="generalData.location_address1" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Shop Location Address Line 2" label-for="business-location_address2" class="mb-2">
                <b-form-input id="business-location_addres2" v-model="generalData.location_address2" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Shop Location City" label-for="business-location_city" class="mb-2">
                <b-form-input id="business-location_city" v-model="generalData.location_city" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Shop Location County" label-for="business-location_county" class="mb-2">
                <b-form-input id="business-location_county" v-model="generalData.location_county" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Shop Location Country" label-for="business-location_country" class="mb-2">
                <b-form-input id="business-location_country" v-model="generalData.location_country" />
              </b-form-group>
            </b-col>
            <!-- buttons -->
            <b-col cols="12">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1 mt-1" @click="saveChanges()"> Save changes </b-button>
            </b-col>
            <!--/ buttons -->
          </b-row>
        </b-form>
      </b-card>
    </b-tab>

    <!-- Customise Shop -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="PackageIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Customise</span>
      </template>

      <b-card>
        <!-- form -->
        <b-form class="">
          <b-row>
            <h6 class="section-label mx-1 mb-2">Shop Customise Settings</h6>
            <b-col md="12">
              <div class="border rounded p-2">
                <h4 class="mb-1">Company Logo</h4>
                (Recommended Size 50 pixels height)<br />
                <b-media no-body vertical-align="center" class="flex-column flex-md-column">
                  <b-media-aside v-if="generalData.logo">
                    <b-img ref="refPreviewEl" :src="generalData.logo" height="100" class="rounded mr-2 mb-1 mb-md-0" style="height: 100px; width: auto !important; object-fit: contain;background: #d2d2d2;" />
                  </b-media-aside>
                  <b-media-body>
                    <div class="d-inline-block">
                      <input type="file" accept=".jpg, .png" size="300000" placeholder="Choose file" ref="refInputEl" @input="inputImageRenderer" @change="toDataURL('logo')" />
                    </div>

                    <div v-if="this.generalData.logo">
                    <br/>
                    <button v-ripple.400="'rgba(255, 255, 255, 0.15)'"  @click.prevent="removeImage('logo')" class="btn btn-danger">Remove Image</button>
                    </div>
                  </b-media-body>
                </b-media>
              </div>
            </b-col>
            <b-col md="12">
              <div class="border rounded p-2">
                <h4 class="mb-1">Featured Image</h4>
                <b-media no-body vertical-align="center" class="flex-column flex-md-column">
                  (Recommended Size 1920 x 400 pixels)<br /><br />
                  <b-media-aside v-if="generalData.featured_image" style="width: 100%">
                    <b-img ref="refPreviewEl2" :src="generalData.featured_image" height="133" width="500" class="rounded mr-2 mb-1 mb-md-0" style="object-fit: contain;background: #d2d2d2;" />
                  </b-media-aside>
                  <b-media-body>
                    <div class="d-inline-block">
                      <input type="file" accept=".jpg, .png" size="300000" placeholder="Choose file" ref="refInputEl2" @input="inputImageRenderer" @change="toDataURL('featured')" />
                    </div>

                    <div v-if="this.generalData.featured_image">
                    <br/>
                    <button v-ripple.400="'rgba(255, 255, 255, 0.15)'"  @click.prevent="removeImage('featured_image')" class="btn btn-danger">Remove Image</button>
                    </div>
                  </b-media-body>
                </b-media>
              </div>
            </b-col>

            <b-col md="6">
              <b-form-group label="Primary Color" label-for="customise_primary_color" class="mb-2">
                <b-form-input type="color" id="customise_primary_color" v-model="generalData.customise_primary_color" />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Secondary Color" label-for="customise_secondary_color" class="mb-2">
                <b-form-input type="color" id="customise_secondary_color" v-model="generalData.customise_secondary_color" />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Primary Text Color" label-for="customise_primary_text_color" class="mb-2">
                <b-form-input type="color" id="customise_primary_text_color" v-model="generalData.customise_primary_text_color" />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Secondary Text Color" label-for="customise_secondary_text_color" class="mb-2">
                <b-form-input type="color" id="customise_secondary_text_color" v-model="generalData.customise_secondary_text_color" />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <div class="border rounded p-2">
                <h4 class="mb-1">FAVICON</h4>
                <b-media no-body vertical-align="center" class="flex-column flex-md-column">
                  (Recommended Size 192 x 192 pixels)<br /><br />
                  <b-media-aside v-if="generalData.favicon" style="width: 100%">
                    <b-img ref="refPreviewEl3" :src="generalData.favicon" height="192" width="192" class="rounded mr-2 mb-1 mb-md-0" style="object-fit: contain;background: #d2d2d2;" />
                  </b-media-aside>
                  <b-media-body>
                    <div class="d-inline-block">
                      <input type="file" accept=".jpg, .png" size="300000" placeholder="Choose file" ref="refInputEl3" @input="inputImageRenderer" @change="toDataURL('favicon')" />
                    </div>
                    <div v-if="this.generalData.favicon">
                    <br/>
                    <button v-ripple.400="'rgba(255, 255, 255, 0.15)'"  @click.prevent="removeImage('favicon')" class="btn btn-danger">Remove Image</button>
                    </div>
                  </b-media-body>
                </b-media>
              </div>
            </b-col>


            <b-col md="12">
              <b-col md="4">
                <b-form-group label="Show Missing Image" label-for="missing_image" class="mb-2">
                  <b-form-select id="missing_image" :options="discountOption" v-model="generalData.missing_image" />
                </b-form-group>
              </b-col>
              <div class="border rounded p-2">
                <h4 class="mb-1">Image Missing Placeholder</h4>
                <b-media no-body vertical-align="center" class="flex-column flex-md-column">
                  (Recommended Size 512 x 512 pixels)<br /><br />
                  <b-media-aside v-if="generalData.missing_image_placeholder" style="width: 100%">
                    <b-img ref="refPreviewEl4" :src="generalData.missing_image_placeholder" height="192" width="192" style="object-fit: contain;background: #d2d2d2;" class="rounded mr-2 mb-1 mb-md-0" />
                  </b-media-aside>
                  <b-media-body>
                    <div class="d-inline-block">
                      <input type="file" accept=".jpg, .png" size="300000" placeholder="Choose file" ref="refInputEl4" @input="inputImageRenderer" @change="toDataURL('missing_image_placeholder')" />
                    </div>

                    <div v-if="this.generalData.missing_image_placeholder">
                    <br/>
                    <button v-ripple.400="'rgba(255, 255, 255, 0.15)'"  @click.prevent="removeImage('missing_image_placeholder')" class="btn btn-danger">Remove Image</button>
                    </div>
                  </b-media-body>
                </b-media>
              </div>
            </b-col>
            <!-- buttons -->
            <b-col cols="12">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1 mt-1" @click="saveChanges"> Save changes </b-button>
            </b-col>
            <!--/ buttons -->
          </b-row>
        </b-form>
      </b-card>
    </b-tab>

    <!-- SEO  -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="PieChartIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">SEO</span>
      </template>
      <b-card>
        <b-form class="">
          <b-row>
            <h6 class="section-label mx-1 mb-2">SEO Settings</h6>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label="Google Tag Manager Code" label-for="analytics_google_tag" class="mb-2">
                <b-form-input id="analytics_google_tag" v-model="generalData.analytics_google_tag" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Meta Description" label-for="seo_description" class="mb-2">
                <b-form-input id="seo_description" v-model="generalData.seo_description" />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <!-- buttons -->
            <b-col cols="12">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1 mt-1" @click="saveChanges()"> Save changes </b-button>
            </b-col>
            <!--/ buttons -->
          </b-row>
        </b-form>
      </b-card>
    </b-tab>

    <!-- Payment Gateways -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="HomeIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Payment Gateway</span>
      </template>

      <b-card>
        <!-- form -->
        <b-form class="">
          <b-row>
            <b-col md="12">
            <b-col md="12">
              <h6 class="section-label mx-1 mb-2">GENERAL</h6>
              <b-row>
                <b-col md="4"><b-form-group label="Service Charge for online payments ( 0 to disable )" label-for="business-service_charge" class="mb-2">
                    <b-form-input id="business-service_charge" v-model="generalData.service_charge" />
                  </b-form-group></b-col>
                <b-col md="4">
                  <b-form-group label="Payment Gateway" label-for="paymentGateway" class="mb-2">
                    <b-form-select id="paymentGateway" :options="paymentOptions"  
                    @change="onPaymentGatewayChange" v-model="generalData.paymentGateway" />
                  </b-form-group></b-col>
                <b-col md="4" v-if="generalData.paymentGateway">
                  
                  <b-form-group label="Live Payments?" label-for="live_payment" class="mb-2">
                      <b-form-select id="live_payment" :options="discountOption" v-model="generalData.live_payment" />
                    </b-form-group>
                    
                    </b-col>
              </b-row>

            </b-col>
            </b-col>
          </b-row>
          <br />
          <b-row>

            <!-- STRIPE CONNECTION FORM -->
            <b-col md="12" v-if="generalData.paymentGateway == 'stripe'">
              <b-row>
                <b-col md="6">
                  <h6 class="section-label mx-1 mb-2">Stripe connection</h6>

                  <b-col md="12">
                    <b-form-group label="Stripe Public Key" label-for="business-stripe_public" class="mb-2">
                      <b-form-input id="business-stripe_public" v-model="generalData.stripe_public" />
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group label="Stripe Private Key" label-for="business-stripe_private" class="mb-2">
                      <b-form-input id="business-stripe_private" v-model="generalData.stripe_private" />
                    </b-form-group>
                  </b-col> 
                </b-col>
                <b-col md="6">
                  <h6 class="section-label mx-1 mb-2">TEST Stripe connection</h6>

                  <b-col md="12">
                    <b-form-group label="[TEST] Stripe Public Key" label-for="business-test_stripe_public" class="mb-2">
                      <b-form-input id="business-test_stripe_public" v-model="generalData.test_stripe_public" />
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group label="[TEST] Stripe Private Key" label-for="business-test_stripe_private" class="mb-2">
                      <b-form-input id="business-test_stripe_private" v-model="generalData.test_stripe_private" />
                    </b-form-group>
                  </b-col>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <!-- SAGE CONNECTION FORM -->

            <template v-if="generalData.paymentGateway == 'sage'">

            <b-col md="6">
              <h6 class="section-label mx-1 mb-2">Opayo connection</h6>
              <b-col md="12">
                <b-form-group label="Opayo Vendor" label-for="business-sage_vendor" class="mb-2">
                  <b-form-input id="business-sage_vendor" v-model="generalData.sage_vendor" />
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group label="Opayo Key" label-for="business-sage_key" class="mb-2">
                  <b-form-input id="business-sage_key" v-model="generalData.sage_key" />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="Opayo Password" label-for="business-sage_password" class="mb-2">
                  <b-form-input id="business-sage_password" v-model="generalData.sage_password" />
                </b-form-group>
              </b-col>
       
            </b-col>
            <b-col md="6">
              <h6 class="section-label mx-1 mb-2">TEST Opayo connection</h6>
              <b-col md="12">
                <b-form-group label="TEST Opayo Vendor" label-for="business-test_sage_vendor" class="mb-2">
                  <b-form-input id="business-test_sage_vendor" v-model="generalData.test_sage_vendor" />
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group label="TEST Opayo Key" label-for="business-test_sage_key" class="mb-2">
                  <b-form-input id="business-test_sage_key" v-model="generalData.test_sage_key" />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="TEST Sage Password" label-for="business-test_sage_password" class="mb-2">
                  <b-form-input id="business-test_sage_password" v-model="generalData.test_sage_password" />
                </b-form-group>
              </b-col>
       
            </b-col>

          </template>
            <!-- buttons -->
            <b-col cols="12">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1 mt-1" @click="saveChanges()"> Save changes </b-button>
            </b-col>

            <!--/ buttons -->
          </b-row>
        </b-form>
      </b-card>
    </b-tab>

    <!-- Business Hours -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="BellIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Business Hours</span>
      </template>

      <b-card>
        <!-- form -->
        <b-form class="">
          <b-row>
            <h6 class="section-label mx-1 mb-2">Business Hours Settings</h6>
          </b-row>
          <b-row v-for="(day, dayIndex) in weekDays" :key="dayIndex">
            <b-col md="3">
              <b-form-group :label="day + ' Open'" :label-for="'business-open-' + day" class="mb-2">
                <b-time locale="en" :id="'business-open-' + day" v-model="generalData[day + '_open']" />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Closing Time" :label-for="'business-close-' + day" class="mb-2">
                <b-time locale="en" :id="'business-close-' + day" v-model="generalData[day + '_close']" />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <!-- buttons -->
            <b-col cols="12">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1 mt-1" @click="saveChanges()"> Save changes </b-button>
            </b-col>
            <!--/ buttons -->
          </b-row>
        </b-form>
      </b-card>
    </b-tab>

    <!-- Delivery settings -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="BellIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Delivery</span>
      </template>
      <b-card>
        <b-form class="">
          <b-row>
            <h6 class="section-label mx-1 mb-2">Delivery Settings</h6>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group label="Do you want to enable delivery" label-for="delivery" class="mb-2">
                <b-form-select id="delivery" :options="discountOption" v-model="generalData.delivery" />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Delivery Cost" label-for="delivery_charge" class="mb-2">
                <b-form-input id="delivery_charge" v-model="generalData.delivery_charge" />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Minimum Spent For Delivery" label-for="delivery_minimum_spent" class="mb-2">
                <b-form-input id="delivery_minimum_spent" v-model="generalData.delivery_minimum_spent" />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-for="(day, dayIndex) in weekDays" :key="dayIndex">
            <b-col md="3">
              <b-form-group :label="day + ' Starts'" :label-for="'business-delivery-open-' + day" class="mb-2">
                <b-time locale="en" :id="'business-delivery-open-' + day" v-model="generalData['delivery_' + day + '_open']" />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Ends" :label-for="'business-delivery-close-' + day" class="mb-2">
                <b-time locale="en" :id="'business-delivery-close-' + day" v-model="generalData['delivery_' + day + '_close']" />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <!-- buttons -->
            <b-col cols="12">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1 mt-1" @click="saveChanges()"> Save changes </b-button>
            </b-col>
            <!--/ buttons -->
          </b-row>
        </b-form>
      </b-card>
    </b-tab>

    <!-- Miscellaneous  -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="PocketIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Miscellaneous</span>
      </template>
      <b-card>
        <b-form class="">
          <b-row>
            <h6 class="section-label mx-1 mb-2">Miscellaneous Settings</h6>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group label="Do you want to temporary close shop?" label-for="close_shop" class="mb-2">
                <b-form-select id="close_shop" :options="discountOption" v-model="generalData.close_shop" />
              </b-form-group>
            </b-col>
            <b-col md="8">
              <b-form-group label="Message for closed shop" label-for="close_shop_message" class="mb-2">
                <b-form-input id="business-close_shop_message" v-model="generalData.close_shop_message" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- buttons -->
            <b-col cols="12">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1 mt-1" @click="saveChanges()"> Save changes </b-button>
            </b-col>
            <!--/ buttons -->
          </b-row>
        </b-form>
      </b-card>
    </b-tab>
  </b-tabs>
</template>

<script>
const aws = require("aws-sdk");

aws.config.update({
  accessKeyId: "AKIARLZ53ZXES6XSB6UR",
  secretAccessKey: "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",
});

export const s3 = new aws.S3({
  signatureVersion: "v4",
  region: "eu-west-1",
});

import {
  BFormFile,
  BButton,
  BForm,
  BTabs,
  BTab,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BLink,
  BImg,
  BTime,
  BMediaAside,
  BMedia,
  BMediaBody,
  BFormSelect,
  BFormColor,
  BFormCheckbox,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";

import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import axios from "axios";
import general from "@/router/routes/general";

export default {
  components: {
    BTabs,
    BTab,
    BFormFile,
    BButton,
    BForm,
    BFormGroup,

    BMediaAside,
    BMedia,
    BMediaBody,
    BFormInput,
    BFormColor,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BLink,
    BImg,
    BTime,
    BFormSelect,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      generalData: JSON.stringify(localStorage.getItem("userSettings")),
      discountOption: [
        { value: 0, text: "No" },
        { value: 1, text: "Yes" },
      ],
      options: [
        { value: 0, text: "No" },
        { value: 1, text: "Yes" },
      ],
      paymentOptions: [
        { value: '', text: "None" },
        { value: 'stripe', text: "Stripe" },
        { value: 'sage', text: "Sage / Opayo" },
      ],
      
      weekDays: ["monday", "tuesday", "wednessday", "thursday", "friday", "saturday", "sunday"],
    };
  }, 
  watch: {
    'generalData.paymentGateway': {
      handler(val) {
        console.log('Payment Gateway changed to:', val);
      },
      deep: true,
    },
  },
  methods: {
    removeImage(type){ 
      if(type == "logo"){
        this.generalData.logo = "";
      } else if(type == "featured"){
        this.generalData.featured_image = "";
      } else if(type == "favicon"){
        this.generalData.favicon = "";
      } else if(type == "missing_image_placeholder"){
        this.generalData.missing_image_placeholder = "";
      }
      this.saveChanges()
      return this.$forceUpdate();
    },
    onPaymentGatewayChange(value) {
      console.log('Selected Payment Gateway:', value);
      // Perform any additional actions if needed
      this.$forceUpdate();
    },
    async toDataURL(to) {
      let file, key;
      if (to == "logo") {
        file = this.$refs.refInputEl.files[0];
        key = "tenant-" + JSON.parse(localStorage.getItem("userData")).tenant_id + "/" + Date.now() + "-" + file.name;
      }
      if (to == "featured") {
        file = this.$refs.refInputEl2.files[0];
        key = "tenant-" + JSON.parse(localStorage.getItem("userData")).tenant_id + "/" + Date.now() + "-" + file.name;
      }
      if (to == "favicon") {
        file = this.$refs.refInputEl3.files[0];
        key = "tenant-" + JSON.parse(localStorage.getItem("userData")).tenant_id + "/" + Date.now() + "-" + file.name;
      }
      if (to == "missing_image_placeholder") {
        file = this.$refs.refInputEl4.files[0];
        key = "tenant-" + JSON.parse(localStorage.getItem("userData")).tenant_id + "/" + Date.now() + "-" + file.name;
      }

      const params = {
        Bucket: "tenants-app",
        Key: key,
        Expires: 10,
        ContentType: file.type,
      };
      const url = s3.getSignedUrl("putObject", params);
      return axios
        .put(url, file, {
          headers: {
            "Content-Type": file.type,
          },
        })
        .then((result) => {
          const bucketUrl = decodeURIComponent(result.request.responseURL).split(key)[0];

          if (to == "logo") {
            this.generalData.logo = bucketUrl + key;
          }
          if (to == "featured") {
            this.generalData.featured_image = bucketUrl + key;
          }
          if (to == "favicon") {
            this.generalData.favicon = bucketUrl + key;
          }
          if (to == "missing_image_placeholder") {
            this.generalData.missing_image_placeholder = bucketUrl + key;
          }
          return this.$forceUpdate();
        })
        .catch((err) => {
          // TODO: error handling
          console.log(err);
        });
    },   

    saveChanges() {
      let data = [],
        single = {}; 
if(this.generalData.service_charge == undefined){
  this.generalData.service_charge = 0
}
      for (let x = 0; x < Object.keys(this.generalData).length; x++) {
        single.key = Object.keys(this.generalData)[x];
         if (single.key == "paymentGateway"){
single.value = (this.generalData[Object.keys(this.generalData)[x]] ? this.generalData[Object.keys(this.generalData)[x]] : showGateway);
           } else {
          single.value = this.generalData[Object.keys(this.generalData)[x]];
        }
        data.push(single);
        single = {};
      }
      useJwt
        .patch_settings({ multiple: data })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Saved`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `Settings has been saved.`,
            },
          });
          localStorage.setItem("userSettings", JSON.stringify(this.generalData));
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "CropIcon",
              variant: "danger",
              text: `We couldn't downlad your order data from the server.`,
            },
          });
        });
    },
  },
  beforeCreate() {
    useJwt
      .get_settings()
      .then((response) => {
        this.generalData = {};
        for (let x = 0; x < response.data.data.length; x++) {
          this.generalData[response.data.data[x].meta_key] = response.data.data[x].meta_value;
        }
        return localStorage.setItem("userSettings", JSON.stringify(this.generalData));
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Error`,
            icon: "CropIcon",
            variant: "danger",
            text: `We couldn't downlad your order data from the server.`,
          },
        });
      });
  },
  setup() {
    const refInputEl = ref(null);
    const refInputEl2 = ref(null);
    const refInputEl3 = ref(null);
    const refInputEl4 = ref(null);
    const refPreviewEl = ref(null);
    const refPreviewEl2 = ref(null);
    const refPreviewEl3 = ref(null);
    const refPreviewEl4 = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, (base64) => {
      refPreviewEl.value.src = base64;
    });
    const { inputImageRenderer2 } = useInputImageRenderer(refInputEl2, (base64) => {
      refPreviewEl2.value.src = base64;
    });
    const { inputImageRenderer3 } = useInputImageRenderer(refInputEl3, (base64) => {
      refPreviewEl3.value.src = base64;
    });
    const { inputImageRenderer4 } = useInputImageRenderer(refInputEl4, (base64) => {
      refPreviewEl4.value.src = base64;
    });

    return {
      refInputEl,
      refInputEl2,
      refInputEl3,
      refInputEl4,
      refPreviewEl,
      refPreviewEl2,
      refPreviewEl3,
      refPreviewEl4,
      inputImageRenderer,
      inputImageRenderer2,
      inputImageRenderer3,
      inputImageRenderer4,
    };
  },
};
</script>
